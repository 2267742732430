export const font = {
  TITLE: "'Montserrat', sans-serif",
  BODY: "'Proxima Nova', 'Open Sans', sans-serif",
}

export const fontSize = {
  TITLE: 30,
  HISTORIATED: 50,
  SUBTITLE: 20,
  BODY: 15,
}